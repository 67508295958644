
export default {
  head() {
    const meta = [];
    if (this.$store.state.staging) {
      meta.push({ hid: "robots", name: "noindex", content: "" });
    }
    return {
      meta: meta,
    };
  },
};
