import { computed } from "vue";
import { useRoute, useStore } from "@nuxtjs/composition-api";

export const useHeaderToolbar = () => {
  const store = useStore();
  const route = useRoute();

  const { getters } = useStore();
  const toolbar = computed(() => getters["headerToolbar/loadedToolbar"]);

  function setToolbar(type) {
    store.commit("headerToolbar/setToolbar", type);
  }

  function getTargetToolbarFromCurrentPath() {
    let toolbar;

    switch (route.value.name) {
      case "locale-company-csr":
      case "locale-company-dna-design":
      case "locale-company-overview":
      case "locale-company-quality":
      case "locale-company-waterfront":
        toolbar = "company";
        break;
      case "locale-account-profile":
      case "locale-account-lists":
        toolbar = "account";
        break;
      case "locale-press-kit":
      case "locale-press-request":
      case "locale-press-review":
        toolbar = "press";
        break;
      case "locale-serie-id-concept":
      case "locale-serie-id-products":
      case "locale-serie-id-gallery":
      case "locale-serie-id-downloads":
        toolbar = "serie";
        break;
      case "locale-showroom-id":
        toolbar = "showroom";
        break;
      case "locale-legal-info-cookie-policy":
      case "locale-legal-info-privacy-policy":
      case "locale-legal-info-legal-notes":
        toolbar = "legal-info";
        break;
      case "locale-contacts-customer-care-and-spare-parts":
      case "locale-contacts-customer-service":
      case "locale-contacts-headquarters-and-showrooms":
      case "locale-contacts-professional-consulting":
      case "locale-contacts-sales-points":
        toolbar = "contacts";
        break;
      case "locale-find-products":
        toolbar = "find-products";
        break;
      case "locale-download-area":
        if (route.value.query?.tab === "out-of-production") {
          toolbar = "download-area_out-of-production";
        }
        break;
    }

    return toolbar;
  }

  function getToolbar() {
    return getters["headerToolbar/loadedToolbar"];
  }

  return {
    toolbar,
    setToolbar,
    getToolbar,
    getTargetToolbarFromCurrentPath,
  };
};
