import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

if (process.client) {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, SplitText);

  window.gsap = gsap;
  window.ScrollTrigger = ScrollTrigger;
  window.SplitText = SplitText;
}
