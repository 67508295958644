export default {
  __name: 'index',
  props: {
  useGapX: {
    type: Boolean,
    required: false,
    default: true,
  },
  useGapY: {
    type: Boolean,
    required: false,
    default: true,
  },
  gapXClass: {
    type: String,
    required: false,
    default:
      "gap-x-6 sm:gap-x-8 md:gap-x-10 lg:gap-x-14 xl:gap-x-18 2xl:gap-x-24",
  },
  gapYClass: {
    type: String,
    required: false,
    default: "gap-y-10 lg:gap-y-14 xl:gap-y-18 2xl:gap-y-24",
  },
  useDense: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props



return { __sfc: true,props }
}

}