import { ref } from "vue";
import { useImageSources } from "/composables/useImageSources";
import { useDownloadables } from "/composables/useDownloadables";
import { useUser } from "/composables/useUser";


export default {
  __name: 'index',
  props: {
  download: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  details: {
    type: Object,
    required: true,
  },
  header: {
    type: String,
    required: true,
  },
  media: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const { getSrc } = useImageSources();
const { getLink } = useDownloadables();
const { logged } = useUser();

const showModal = ref(false);

function handleClick(event) {
  if (!logged.value && props.isPrivate) {
    event.preventDefault();
    event.stopPropagation();
    showModal.value = true;
  }
}

return { __sfc: true,props, getSrc, getLink, logged, showModal, handleClick }
}

}