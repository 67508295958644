import { ref } from "vue";
import { useStore } from "@nuxtjs/composition-api";
import { useLocale } from "/composables/useLocale";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";


export default {
  __name: 'index',
  setup(__props) {

const store = useStore();

const formValues = ref({
  language: store.state.lang,
  market: store.state.market,
});
const { changeLocale } = useLocale();
const { onSubmit } = useRecaptcha3();

async function handleSubmit(formValues) {
  const recaptchaToken = await onSubmit();
  if (recaptchaToken) {
    changeLocale(formValues.language, formValues.market);
  }
}

return { __sfc: true,store, formValues, changeLocale, onSubmit, handleSubmit }
}

}