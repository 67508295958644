export default function ({ store, route }) {
  if (route.path == "/auth") {
    return;
  }
  if (process.client) {
    return;
  }
  /* EXECUTED ONLY AT GENERATE TIME! */
  function testLocale(locale) {
    const locales = [];
    for (let i = 0; i < store.state.markets.length; i++) {
      for (let j = 0; j < store.state.langs.length; j++) {
        locales.push(`${store.state.langs[j]}-${store.state.markets[i]}`);
      }
    }
    return new RegExp(`^${locales.join("$|^")}$`).test(locale);
  }
  if (testLocale(route.params.locale)) {
    // valid locale
    const lang = route.params.locale.split("-")[0].toLowerCase();
    const market = route.params.locale.split("-")[1].toLowerCase();
    store.commit("setLocale", {
      lang: lang,
      market: market,
    });
  }
  return;
}
