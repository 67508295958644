// Import Swiper and modules
import "swiper/swiper-bundle.min.css";

import {
  Virtual,
  Autoplay,
  EffectFade,
  Pagination,
  Navigation,
  Zoom,
  Parallax,
  Thumbs,
  Controller,
  Mousewheel,
} from "swiper";

if (process.client) {
  window.Swiper = require("swiper").default;

  // Install modules
  Swiper.use([
    Virtual,
    Autoplay,
    EffectFade,
    Pagination,
    Navigation,
    Zoom,
    Parallax,
    Thumbs,
    Controller,
    Mousewheel,
  ]);
}
