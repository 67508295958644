import { computed } from "vue";
import { templateRef } from "@vueuse/core";

export default {
  __name: 'index',
  props: {
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "text",
          "textarea",
          "email",
          "hidden",
          "password",
          "number",
          "tel",
          "url",
        ].indexOf(value) !== -1
      );
    },
    default: "text",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  validation: {
    type: String,
    required: false,
  },
  validationMessages: {
    type: Object,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  changeCallback: {
    type: Function,
    required: false,
    default: function (event) {},
  },
  autocomplete: {
    type: Boolean,
    required: false,
    default: false,
  },
  autofill: {
    type: Boolean,
    required: false,
    default: false,
  },
  autocorrect: {
    type: Boolean,
    required: false,
    default: false,
  },
  autocapitalize: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxlength: {
    type: Number,
    required: false,
  },
},
  setup(__props) {

const props = __props

const tpl_root = templateRef("tpl_root");


const inputClass = computed(() => {
  const type =
    props.type === "textarea"
      ? "h-40 min-h-[80px] max-h-[60vh]"
      : "h-14 !border-t-0 !border-r-0 !border-l-0";
  const colors = `border border-current disabled:!pointer-events-none`;
  const text = `text-skin-muted placeholder-shown:!text-skin-muted valid:!text-skin-base text-body`;
  const hover = `hover:bg-skin-surface hover:text-skin-base hover:border-current`;
  const focus = `focus:!outline-none focus:ring-transparent focus:bg-skin-surface focus:text-skin-base focus:border-current`;
  const transition = `transition-colors duration-300 ease-in-out`;

  return `w-full ${type} ${colors} ${text} ${hover} ${focus} ${transition}`;
});

const wrapperClass = computed(() => {
  const base = "relative top-0 left-0 inline-flex flex-col w-full";
  const disabled = props.disabled ? "!pointer-events-none" : "";

  return `${base} ${disabled}`;
});

return { __sfc: true,tpl_root, props, inputClass, wrapperClass }
}

}