import { ref, onMounted } from "vue";
import { debouncedWatch, templateRef, useElementBounding } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  id: {
    type: String,
    required: false,
  },
  skin: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        value === "" || ["white", "ice", "red", "gray"].indexOf(value) !== -1
      );
    },
  },
  contentVisibility: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "hidden", "visible"].indexOf(value) !== -1;
    },
    default: "visible",
  },
  bgText: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("tpl_root");
const tpl_bgText = templateRef("tpl_bgText");
const { width, height } = useElementBounding(tpl_root);
const bgTextVisible = ref(false);

if (props.bgText) {
  debouncedWatch(
    width,
    () => {
      handleBgText();
    },
    { debounce: 250 }
  );
}

function handleBgText() {
  gsap.killTweensOf(tpl_bgText.value);

  requestAnimationFrame(() => {
    gsap.set(tpl_bgText.value, {
      fontSize: (width.value / (props.bgText.length * 0.5)) * 0.74,
    });

    gsap.fromTo(
      tpl_bgText.value,
      {
        yPercent: -7,
        scale: 0.95,
      },
      {
        scrollTrigger: {
          scrub: 1,
          trigger: tpl_root.value,
          start: "top 15%",
          end: "bottom bottom",
          onEnter: () => {
            bgTextVisible.value = true;
          },
          onEnterBack: () => {
            bgTextVisible.value = true;
          },
          onLeave: () => {
            bgTextVisible.value = false;
          },
          onLeaveBack: () => {
            bgTextVisible.value = false;
          },
        },
        transformOrigin: "top center",
        scale: 1,
        yPercent: 12,
        force3D: true,
        ease: "slow(0.3, 0.7, false)",
      }
    );
  });
}

onMounted(() => {
  if (props.bgText) {
    handleBgText();
  }
});

return { __sfc: true,props, tpl_root, tpl_bgText, width, height, bgTextVisible, handleBgText }
}

}