import { useUser } from "/composables/useUser";
import { useRouter, useRoute, useStore } from "@nuxtjs/composition-api";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props




const { logged } = useUser();
const store = useStore();
const router = useRouter();
const route = useRoute();

function handleSigninSuccess() {
  emit("hide");
  if (logged.value && route.value.name != "locale-lists-id") {
    router.push(`/${store.state.locale}/account/lists`);
  }
}

return { __sfc: true,props, emit, logged, store, router, route, handleSigninSuccess }
}

}