import axios from "axios";
import Vue from "vue";

export const state = () => ({});

export const mutations = {
  setData(state, data) {
    for (let prop in data) {
      Vue.set(state, prop, data[prop]);
    }
  },
};

export const actions = {
  async load({ commit }, params) {
    if (params.payload) {
      commit("setData", params.payload);
      return;
    }
    // const { data } = await axios.get(
    //   `${this.$config.wsURL}/wp-json/ta/v1/get?type=page&page=locales&lang=${params.lang}`
    // );

    /*
        IMPORTANT
          Can't access .json client side CORS
    */

    // const { data } = await axios.get(
    //   process.server
    //     ? `${this.$config.wsURL}/wp-content/json/cached/locales-lng-${
    //         params.market == "us" && params.lang == "en" ? "us" : params.lang
    //       }.json`
    //     : `${
    //         this.$config.wsURL
    //       }/wp-json/ta/v1/get?type=page&page=locales&lang=${
    //         params.market == "us" && params.lang == "en" ? "us" : params.lang
    //       }`
    // );
    const { data } = await axios.get(
      `${this.$config.wsURL}/wp-json/ta/v1/get?type=page&page=locales&lang=${
        params.market == "us" && params.lang == "en" ? "us" : params.lang
      }`
    );

    commit("setData", data);
  },
};
