import { render, staticRenderFns } from "./index.vue?vue&type=template&id=174e2b26&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TransitionFade: require('/var/www/html/fantini/frontend/components/transition/fade/index.vue').default})
