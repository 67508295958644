import axios from "axios";
import Vue from "vue";
import pick from "lodash/pick";
import omit from "lodash/omit";

const model = {
  id: null,
  token: null,
  first_name: null,
  last_name: null,
  email: null,
  confirm_email: null,
  professional_category: null,
  job_role: null,
  country: null,
  province: null,
  city: null,
  privacy_policy: false,
  marketing: false,
  company: null,
  region: null,
  area: null,
};

export const state = () => ({ ...model });

export const mutations = {
  setUser(state, data) {
    if (!data) {
      this.$cookies.remove("ft-token");
      this.$cookies.remove("ft-id");

      for (let p in state) {
        Vue.set(state, p, null);
      }
      return;
    }

    data = pick(data, Object.keys(model));

    for (let p in data) {
      Vue.set(state, p, data[p]);
    }

    if (data.token) {
      this.$cookies.set("ft-token", data.token, {
        path: "/",
        maxAge: 60 * 60 * 24 * 400,
      });
    }
    if (data.id) {
      this.$cookies.set("ft-id", data.id, {
        path: "/",
        maxAge: 60 * 60 * 24 * 400,
      });
    }
  },
  setNewToken(state, data) {
    const token = "0987654321";
    state.token = data;
  },
};

export const actions = {
  async userLogin({ commit, rootState }, payload) {
    const { data } = await axios
      .post(
        `${rootState.wsPath}get?type=users&action=login&lang=${rootState.lang}&market=${rootState.market}`,
        payload
      )
      .catch(function (error) {
        console.log("userLogin:", error);
      });

    commit("setUser", data);
    return data;
  },

  async userLogout({ commit, rootState }) {
    // call to login API
    const { data } = await axios
      .post(
        `${rootState.wsPath}get?type=users&action=logout&id=${rootState.user.id}&lang=${rootState.lang}&market=${rootState.market}`
      )
      .catch(function (error) {
        console.log("userLogin:", error);
      });

    if (data.success) {
      commit("setUser", null);
    }
    return data;
  },

  async userSignup({ commit, rootState }, payload) {
    /*
      code: 'existing_user_login',
    */

    const { data } = await axios
      .post(
        `${rootState.wsPath}get?type=users&action=register&lang=${rootState.lang}&market=${rootState.market}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });

    if (data.token && data.id) {
      commit("setUser", {
        token: data.token,
        id: data.id,
      });
    }

    return data;
  },

  async userUpdate({ commit, rootState }, payload) {
    const { data } = await axios
      .post(
        `${rootState.wsPath}get?type=users&action=update&lang=${rootState.lang}&market=${rootState.market}`,
        JSON.stringify({
          ...omit(payload, ["confirm_email"]),
        })
      )
      .catch(function (error) {
        console.log(error);
      });

    if (data.user && data.user.token && data.user.id) {
      commit("setUser", data.user);
    }

    return data;
  },

  async passwordUpdate({ rootState }, { password, id, token, recaptcha }) {
    const { data } = await axios
      .get(
        `${rootState.wsPath}get?type=users&action=update-password&password=${password}&recaptcha=${recaptcha}&user-id=${id}&token=${token}&lang=${rootState.lang}&market=${rootState.market}`
      )
      .catch(function (error) {
        console.log(error);
      });

    return data;
  },

  async passwordRecovery({ rootState }, { email, recaptcha }) {
    const { data } = await axios
      .get(
        `${rootState.wsPath}get?type=email&email=${email}&recaptcha=${recaptcha}&lang=${rootState.lang}&market=${rootState.market}`
      )
      .catch(function (error) {
        console.log(error);
      });
    return data;
  },

  async passwordReset(
    { commit, rootState },
    { password, email, rk, recaptcha }
  ) {
    const { data } = await axios
      .get(
        `${rootState.wsPath}get?type=email&password=${password}&email=${email}&recaptcha=${recaptcha}&rk=${rk}&lang=${rootState.lang}&market=${rootState.market}`
      )
      .catch(function (error) {
        console.log(error);
      });

    if (data.user && data.user.token && data.user.id) {
      commit("setUser", data.user);
    }

    return data;
  },
};
