
import { ref } from "vue";
import { templateRef } from "@vueuse/core";

export default {
  setup() {
    const TL = ref(null);
    const isShown = ref(false);
    const tpl_root = templateRef("tpl_root");

    function show(done) {
      if (TL.value) {
        TL.value.kill();
        TL.value = null;
      }

      TL.value = gsap.timeline({
        onStart: () => {
          isShown.value = true;
        },
        onComplete: () => {
          window.dispatchEvent(
            new CustomEvent("curtainFullVisible", {
              bubbles: false,
            })
          );
          done();
        },
      });

      TL.value.fromTo(
        tpl_root.value,
        { autoAlpha: 0 },
        { duration: 1, autoAlpha: 1, force3D: "auto", ease: "expo.inOut" }
      );
    }

    function hide() {
      if (TL.value) {
        TL.value.kill();
        TL.value = null;
      }

      TL.value = gsap.timeline({
        onComplete: () => {
          isShown.value = false;
        },
      });

      TL.value.to(tpl_root.value, {
        duration: 1,
        autoAlpha: 0,
        force3D: "auto",
        ease: "expo.out",
      });
    }

    return {
      tpl_root,
      isShown,
      show,
      hide,
    };
  },
};
