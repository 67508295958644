import { useImageSources } from "/composables/useImageSources";

export default {
  __name: 'index',
  props: {
  sku: {
    type: String,
    required: true,
  },
  designer: {
    type: String,
    required: false,
  },
  collections: {
    type: Array,
    required: true,
  },
  serie: {
    type: String,
    required: true,
  },
  finish: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: true,
  },
  media: {
    type: Object,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props

const { getSrc } = useImageSources();



return { __sfc: true,getSrc, props }
}

}