import Vue from "vue";

import { clone, merge } from "lodash";

const ViewportObserver = {
  /*
    // Params

    {
      rootMargin: {
        base: "0px 0px 0px 0px",
        xs: "10px 0px 0px 0px",
        sm: "20px 0px 0px 0px",
        invalid_mq_will_be_ignored: "xxxxxxxxx",
        md: "30px 0px 0px 0px",
        lg: "40px 0px 0px 0px",
        xl: "50px 0px 0px 0px",
        xxl: "60px 0px 0px 0px",
        xxxl: "70px 0px 0px 0px",
        xxxxl: "80px 0px 0px 0px"
      },
      thresholdStep: 0,
      threshold: [0, 1],
      // observers (default false)
      watcherReady: false,
      watcherUpdate: false,
      isInViewport: false,
      isFullyInViewport: true, // always triggers the event
      isAboveViewport: false,
      isBelowViewport: false,
      isFullyAboveViewport: false,
      isFullyBelowViewport: 'once',  // triggers the event once
      hasPartiallyExitViewport: false,
      hasFullyExitViewport: false
    }

    // Events

    @watcher-ready="() => { }"
    @watcher-update="() => { }"
    @is-in-viewport="() => { }"
    @is-fully-in-viewport="() => { }"
    @is-above-viewport="() => { }"
    @is-below-viewport="() => { }"
    @is-fully-above-viewport="() => { }"
    @is-fully-below-viewport="() => { }"
    @has-partially-exit-viewport="() => { }"
    @has-fully-exit-viewport="() => { }"


    // Usage:

    <template>
      <div
        class="my-class"
        v-viewport-observer="params"
        @is-in-viewport="isInViewport"
        @has-fully-exit-viewport="hasFullyExitViewport"
        ...events
      >
        <slot></slot>
      </div>
    </template>

    <script setup>
    function isInViewport() {}

    function hasFullyExitViewport() {}

    const params = {
      //   watcherReady: "watcher-ready---custom-1",
      //   isInViewport: "is-in-viewport---custom-1 is-in-viewport---custom-2",
      //   isFullyInViewport: "is-fully-in-viewport---custom-1",
      //   isAboveViewport: "is-aboooove-1",
      //   isBelowViewport: "is-below-viewport---custom-1",
      //   hasPartiallyExitViewport: "has-partially-exit-viewport---custom-1",
      //   hasFullyExitViewport: "has-fully-exit-viewport---custom-1",
      //   isFullyAboveViewport: "has-fully-above-viewport---custom-1",
      //   isFullyBelowViewport: "has-fully-below-viewport---custom-1",
    };
    </script>
  */
  install: function (Vue, options) {
    Vue.directive("viewport-observer", {
      bind: function (el, binding, vnode, oldVnode) {
        /* called only once, when the directive is first bound to the element. This is where you can do one-time setup work. */
      },

      inserted: function (el, binding, vnode, oldVnode) {
        /* called when the bound element has been inserted into its parent node (this only guarantees parent node presence, not necessarily in-document). */

        const PARAMS = binding.value;

        let rootMargin = PARAMS.rootMargin
          ? PARAMS.rootMargin
          : "0px 0px 0px 0px";
        let thresholdStep = PARAMS.thresholdStep ? PARAMS.thresholdStep : 0;
        let threshold = PARAMS.threshold ? PARAMS.threshold : [0, 1];

        if (typeof rootMargin == "object") {
          for (var p in rootMargin) {
            if (!vnode.context.$tailwindConfig.screens[p]) {
              console.warn(
                '[viewport-observer] Invalid prop "' + p + '" in rootMargin'
              );
              delete rootMargin[p];
            }
          }
          if (Object.keys(rootMargin).length === 0) {
            console.warn(
              "[viewport-observer] rootMargin has not valid media queries. Please use these properties:",
              vnode.context.$tailwindConfig.screens
            );
            rootMargin = "0px 0px 0px 0px";
          }
        }

        const OBSERVERS = {
          watcherReady:
            PARAMS.watcherReady === false ? false : PARAMS.watcherReady,
          watcherUpdate:
            PARAMS.watcherUpdate === false ? false : PARAMS.watcherUpdate,
          isInViewport:
            PARAMS.isInViewport === false ? false : PARAMS.isInViewport,
          isFullyInViewport:
            PARAMS.isFullyInViewport === false
              ? false
              : PARAMS.isFullyInViewport,
          isAboveViewport:
            PARAMS.isAboveViewport === false ? false : PARAMS.isAboveViewport,
          isBelowViewport:
            PARAMS.isBelowViewport === false ? false : PARAMS.isBelowViewport,
          isFullyAboveViewport:
            PARAMS.isFullyAboveViewport === false
              ? false
              : PARAMS.isFullyAboveViewport,
          isFullyBelowViewport:
            PARAMS.isFullyBelowViewport === false
              ? false
              : PARAMS.isFullyBelowViewport,
          hasPartiallyExitViewport:
            PARAMS.hasPartiallyExitViewport === false
              ? false
              : PARAMS.hasPartiallyExitViewport,
          hasFullyExitViewport:
            PARAMS.hasFullyExitViewport === false
              ? false
              : PARAMS.hasFullyExitViewport,
        };

        const elm = vnode.elm;
        const ctx = vnode.context;

        let boundingClientRect;
        let intersectionRatio;
        let intersectionRect;
        let isIntersecting;
        let rootBounds;
        let target;
        let time;
        let viewportState;

        let ready = false;

        const WATCHER_READY = "watcher-ready";
        const WATCHER_UPDATE = "watcher-update";
        const IS_IN_VIEWPORT = "is-in-viewport";
        const IS_FULLY_IN_VIEWPORT = "is-fully-in-viewport";
        const IS_ABOVE_VIEWPORT = "is-above-viewport";
        const IS_BELOW_VIEWPORT = "is-below-viewport";
        const IS_FULLY_ABOVE_VIEWPORT = "is-fully-above-viewport";
        const IS_FULLY_BELOW_VIEWPORT = "is-fully-below-viewport";
        const HAS_PARTIALLY_EXIT_VIEWPORT = "has-partially-exit-viewport";
        const HAS_FULLY_EXIT_VIEWPORT = "has-fully-exit-viewport";

        if (process.client) {
          if (thresholdStep) {
            thresholdStep = parseInt(thresholdStep);
            for (let i = 0.0; i <= thresholdStep - 1; i++) {
              threshold.push(i / (thresholdStep - 1));
            }
          }

          let callback = (entries) => {
            let evt;

            const ENTRY = entries[0];

            boundingClientRect = ENTRY.boundingClientRect;
            intersectionRatio = ENTRY.intersectionRatio;
            intersectionRect = ENTRY.intersectionRect;
            isIntersecting = ENTRY.isIntersecting;
            rootBounds = ENTRY.rootBounds;
            target = ENTRY.target;
            time = ENTRY.time;

            const records = {
              isInViewport: isInViewport(),
              isFullyInViewport: isFullyInViewport(),
              isAboveViewport: isAboveViewport(),
              isBelowViewport: isBelowViewport(),
            };

            const OLD_VIEWPORT_STATE = merge({}, clone(viewportState));

            viewportState = records;

            const DATA = merge(clone(records), {
              boundingClientRect: boundingClientRect,
              intersectionRatio: intersectionRatio,
              intersectionRect: intersectionRect,
              isIntersecting: isIntersecting,
              rootBounds: rootBounds,
              target: target,
              time: time,
            });

            if (OBSERVERS.watcherReady) {
              if (!ready) {
                addClass("watcher-ready", OBSERVERS.watcherReady);
                emitEvent(WATCHER_READY, DATA);
                ready = true;
              }
            }

            if (OBSERVERS.watcherUpdate) {
              emitEvent(WATCHER_UPDATE, DATA);
            }

            if (OBSERVERS.isInViewport) {
              DATA.isInViewport
                ? addClass("is-in-viewport", OBSERVERS.isInViewport)
                : removeClass("is-in-viewport", OBSERVERS.isInViewport);
              if (
                !OLD_VIEWPORT_STATE.isInViewport &&
                records.isInViewport === true
              ) {
                if (OBSERVERS.isInViewport.event) {
                  emitEvent(IS_IN_VIEWPORT, DATA);
                  if (OBSERVERS.isInViewport.event == "once") {
                    OBSERVERS.isInViewport.event = false;
                  }
                }
              }
            }

            if (OBSERVERS.isFullyInViewport) {
              DATA.isFullyInViewport
                ? addClass("is-fully-in-viewport", OBSERVERS.isFullyInViewport)
                : removeClass(
                    "is-fully-in-viewport",
                    OBSERVERS.isFullyInViewport
                  );
              if (
                !OLD_VIEWPORT_STATE.isFullyInViewport &&
                records.isFullyInViewport === true
              ) {
                if (OBSERVERS.isFullyInViewport.event) {
                  emitEvent(IS_FULLY_IN_VIEWPORT, DATA);
                  if (OBSERVERS.isFullyInViewport.event == "once") {
                    OBSERVERS.isFullyInViewport.event = false;
                  }
                }
              }
            }

            if (OBSERVERS.isAboveViewport) {
              DATA.isAboveViewport
                ? addClass("is-above-viewport", OBSERVERS.isAboveViewport)
                : removeClass("is-above-viewport", OBSERVERS.isAboveViewport);
              if (
                !OLD_VIEWPORT_STATE.isAboveViewport &&
                records.isAboveViewport === true
              ) {
                if (OBSERVERS.isAboveViewport.event) {
                  emitEvent(IS_ABOVE_VIEWPORT, DATA);
                  if (OBSERVERS.isAboveViewport.event == "once") {
                    OBSERVERS.isAboveViewport.event = false;
                  }
                }
              }
            }

            if (OBSERVERS.isBelowViewport) {
              DATA.isBelowViewport
                ? addClass("is-below-viewport", OBSERVERS.isBelowViewport)
                : removeClass("is-below-viewport", OBSERVERS.isBelowViewport);
              if (
                !OLD_VIEWPORT_STATE.isBelowViewport &&
                records.isBelowViewport === true
              ) {
                if (OBSERVERS.isBelowViewport.event) {
                  emitEvent(IS_BELOW_VIEWPORT, DATA);
                  if (OBSERVERS.isBelowViewport.event == "once") {
                    OBSERVERS.isBelowViewport.event = false;
                  }
                }
              }
            }

            if (OBSERVERS.hasPartiallyExitViewport) {
              DATA.isInViewport && !DATA.isFullyInViewport
                ? addClass(
                    "has-partially-exit-viewport",
                    OBSERVERS.hasPartiallyExitViewport
                  )
                : removeClass(
                    "has-partially-exit-viewport",
                    OBSERVERS.hasPartiallyExitViewport
                  );
              if (
                OLD_VIEWPORT_STATE.isFullyInViewport === true &&
                !records.isFullyInViewport
              ) {
                if (OBSERVERS.hasPartiallyExitViewport.event) {
                  emitEvent(HAS_PARTIALLY_EXIT_VIEWPORT, DATA);
                  if (OBSERVERS.hasPartiallyExitViewport.event == "once") {
                    OBSERVERS.hasPartiallyExitViewport.event = false;
                  }
                }
              }
            }

            if (OBSERVERS.hasFullyExitViewport) {
              !DATA.isInViewport
                ? addClass(
                    "has-fully-exit-viewport",
                    OBSERVERS.hasFullyExitViewport
                  )
                : removeClass(
                    "has-fully-exit-viewport",
                    OBSERVERS.hasFullyExitViewport
                  );
            }

            if (OBSERVERS.isFullyAboveViewport) {
              !DATA.isInViewport && DATA.isAboveViewport
                ? addClass(
                    "is-fully-above-viewport",
                    OBSERVERS.isFullyAboveViewport
                  )
                : removeClass(
                    "is-fully-above-viewport",
                    OBSERVERS.isFullyAboveViewport
                  );
            }

            if (OBSERVERS.isFullyBelowViewport) {
              !DATA.isInViewport && DATA.isBelowViewport
                ? addClass(
                    "is-fully-below-viewport",
                    OBSERVERS.isFullyBelowViewport
                  )
                : removeClass(
                    "is-fully-below-viewport",
                    OBSERVERS.isFullyBelowViewport
                  );
            }

            if (
              !records.isInViewport &&
              records.isInViewport != OLD_VIEWPORT_STATE.isInViewport
            ) {
              if (OBSERVERS.hasFullyExitViewport?.event) {
                emitEvent(HAS_FULLY_EXIT_VIEWPORT, DATA);
                if (OBSERVERS.hasFullyExitViewport.event == "once") {
                  OBSERVERS.hasFullyExitViewport.event = false;
                }
              }
              if (records.isAboveViewport === true) {
                if (OBSERVERS.isFullyAboveViewport?.event) {
                  emitEvent(IS_FULLY_ABOVE_VIEWPORT, DATA);
                  if (OBSERVERS.isFullyAboveViewport.event == "once") {
                    OBSERVERS.isFullyAboveViewport.event = false;
                  }
                }
              } else if (records.isBelowViewport === true) {
                if (OBSERVERS.isFullyBelowViewport?.event) {
                  emitEvent(IS_FULLY_BELOW_VIEWPORT, DATA);
                  if (OBSERVERS.isFullyBelowViewport.event == "once") {
                    OBSERVERS.isFullyBelowViewport.event = false;
                  }
                }
              }
            }
          };

          let options = {
            /*
              The DOM element
            */
            root: null,
            /*
                Margin around the root.
                Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left).
                If the root element is specified, the values can be percentages.
                This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
                Defaults to all zeros.
            */
            rootMargin: rootMargin,
            /*
                Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
                If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5.
                If you want the callback run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
                The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
                A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
            */
            threshold: threshold,
          };

          ctx.$nextTick(() => {
            if (typeof rootMargin == "object") {
              elm.resizeFcn = (event) => {
                let mq, vw;
                for (
                  let i = vnode.context.$tailwindConfig.mq.length - 1;
                  i >= 0;
                  i--
                ) {
                  mq = vnode.context.$tailwindConfig.mq[i][0];
                  vw = vnode.context.$tailwindConfig.mq[i][1];
                  if (
                    rootMargin[mq] &&
                    window.matchMedia("(min-width: " + vw + ")").matches
                  ) {
                    options.rootMargin = rootMargin[mq];
                    break;
                  }
                }
                if (elm.observer) {
                  elm.observer.disconnect();
                }
                elm.observer = new IntersectionObserver(callback, options);
                elm.observer.observe(el);
              };
              elm.resizeFcn();
              window.addEventListener("resize", elm.resizeFcn);
            } else {
              elm.observer = new IntersectionObserver(callback, options);
              elm.observer.observe(el);
            }
          });

          function removeClass(type, options) {
            if (typeof options == "string") {
              type = type.concat(` ${options}`);
            } else if (typeof options == "object" && options.class) {
              type = type.concat(` ${options.class}`);
            }
            const classes = type.trim().split(" ");
            for (let i = 0; i < classes.length; i++) {
              el.classList.remove(classes[i]);
            }
          }

          function addClass(type, options) {
            if (typeof options == "string") {
              type = type.concat(` ${options}`);
            } else if (typeof options == "object" && options.class) {
              type = type.concat(` ${options.class}`);
            }
            const classes = type.trim().split(" ");
            for (let i = 0; i < classes.length; i++) {
              el.classList.add(classes[i]);
            }
          }

          function emitEvent(event, entry) {
            if (vnode.componentInstance) {
              vnode.componentInstance.$emit(event, entry);
            } else {
              elm.dispatchEvent(
                new CustomEvent(event, {
                  bubbles: false,
                  detail: entry,
                })
              );
            }
          }

          /*
           *  isInViewport: true if any part of the element is visible, false if not.
           */
          function isInViewport() {
            return isIntersecting === true;
          }

          /*
           *  isFullyInViewport: true if the entire element is visible inside the root element
           */
          function isFullyInViewport() {
            return isIntersecting === true && intersectionRatio == 1;
          }

          /*
           *  isAboveViewport: true if any part of the element is above the root
           */
          function isAboveViewport() {
            return boundingClientRect.top < 0;
          }

          /*
           *  isBelowViewport: true if any part of the element is below the root.
           */
          function isBelowViewport() {
            if (!rootBounds) {
              return;
            }

            return boundingClientRect.bottom > rootBounds.bottom;
          }

          /*
           *  top: distance from the top of the root to the top of this watcher.
           */
          function clientTop() {
            return boundingClientRect.top;
          }

          /*
           *  bottom: distance from the top of the root to the bottom of this watcher.
           */
          function clientBottom() {
            return boundingClientRect.bottom;
          }
        }
      },

      update: function (el, binding, vnode, oldVnode) {
        /* called after the containing component’s VNode has updated, but possibly before its children have updated.
        The directive’s value may or may not have changed, but you can skip unnecessary updates by comparing the binding’s current and old values (see below on hook arguments). */
      },

      componentUpdated: function (el, binding, vnode, oldVnode) {
        /* called after the containing component’s VNode and the VNodes of its children have updated. */
      },

      unbind: function (el, binding, vnode, oldVnode) {
        /*  called only once, when the directive is unbound from the element. */
        const elm = vnode.elm;
        const ctx = vnode.context;

        if (elm.resizeFcn) {
          window.removeEventListener("resize", elm.resizeFcn);
        }

        if (!binding.value.keepClassListWhenUnbinded) {
          el.classList.remove("watcher-ready");
          el.classList.remove("is-in-viewport");
          el.classList.remove("is-fully-in-viewport");
          el.classList.remove("is-above-viewport");
          el.classList.remove("is-below-viewport");
          el.classList.remove("has-fully-exit-viewport");
          el.classList.remove("has-partially-exit-viewport");
          el.classList.remove("is-fully-above-viewport");
          el.classList.remove("is-fully-below-viewport");
        }

        if (process.client && elm.observer) {
          elm.observer.disconnect();
        }
      },
    });
  },
};
Vue.use(ViewportObserver);
