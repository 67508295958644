import { render, staticRenderFns } from "./error.vue?vue&type=template&id=26b4ae97&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnPill: require('/var/www/html/fantini/frontend/components/btn/pill/index.vue').default,GridCss: require('/var/www/html/fantini/frontend/components/grid/css/index.vue').default,Block: require('/var/www/html/fantini/frontend/components/block/index.vue').default,BlocksWrapper: require('/var/www/html/fantini/frontend/components/blocks-wrapper/index.vue').default})
