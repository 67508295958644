import { useUser } from "/composables/useUser";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "@nuxtjs/composition-api";
import { useWindowScroll, useWindowSize, throttledWatch } from "@vueuse/core";
import { useHeaderToolbar } from "@/composables/useHeaderToolbar";


export default {
  __name: 'index',
  setup(__props) {

const { logged } = useUser();
const route = useRoute();
const showModalUser = ref(false);
const hide = ref(false);
const { toolbar } = useHeaderToolbar();
const { y } = useWindowScroll();
const { height } = useWindowSize();
const toolbarProp = ref(toolbar);

function handleHtmlClass() {
  const HAS_TOOLBAR = "app-header-has-toolbar";
  const classList = document.getElementsByTagName("html")[0].classList;
  toolbar.value ? classList.add(HAS_TOOLBAR) : classList.remove(HAS_TOOLBAR);
}

watch(toolbarProp, (newVal, oldVal) => {
  handleHtmlClass();
});

watch(route, (newVal, oldVal) => {
  showModalUser.value = false;
});

throttledWatch(
  y,
  (newVal, oldVal) => {
    const classList = document.getElementsByTagName("html")[0].classList;
    const IS_MINIMIZED = "app-header-is-minimized";

    if (oldVal > newVal) {
      hide.value = false;
    } else {
      hide.value = newVal > height.value * 0.45;
    }

    hide.value ? classList.add(IS_MINIMIZED) : classList.remove(IS_MINIMIZED);
  },
  { throttle: 333 }
);

onMounted(() => {
  handleHtmlClass();
});

return { __sfc: true,logged, route, showModalUser, hide, toolbar, y, height, toolbarProp, handleHtmlClass }
}

}