export default {
  __name: 'index',
  props: {
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["multicolor", "monocolor"].indexOf(value) !== -1;
    },
    default: "multicolor",
  },
},
  setup(__props) {

const props = __props



return { __sfc: true,props }
}

}