import { onMounted, onBeforeUnmount } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  alwaysOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("tpl_root");

function handleCollapse(event) {
  if (typeof event.currentTarget.getAttribute("open") == "string") {
    Array.from(tpl_root.value.children).forEach((el) => {
      if (el != event.currentTarget) {
        el.removeAttribute("open");
      }
    });

    ScrollTrigger.refresh(true);
  }
}

if (!props.alwaysOpen) {
  onMounted(() => {
    Array.from(tpl_root.value.children).forEach((el) => {
      el.addEventListener("toggle", handleCollapse);
    });
  });

  onBeforeUnmount(() => {
    Array.from(tpl_root.value.children).forEach((el) => {
      el.removeEventListener("toggle", handleCollapse);
    });
  });
}

return { __sfc: true,props, tpl_root, handleCollapse }
}

}