import { computed } from "vue";
import { useStore, useRouter, useRoute } from "@nuxtjs/composition-api";

export const useUser = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const user = computed(() => store.state.user);

  async function login(email, password, recaptcha) {
    const response = await store.dispatch("user/userLogin", {
      email,
      password,
      recaptcha,
    });
    return response;
  }

  async function logout() {
    const response = await store.dispatch("user/userLogout");
    if (
      (user.value.token === null &&
        route.value.fullPath === `/${store.state.locale}/account/profile`) ||
      route.value.fullPath === `/${store.state.locale}/account/lists`
    ) {
      router.replace(`/${store.state.locale}/`);
    }
    return response;
  }

  async function signup(payload) {
    return await store.dispatch("user/userSignup", payload);
  }

  async function update(payload) {
    return await store.dispatch("user/userUpdate", payload);
  }

  async function passwordUpdate(payload) {
    const response = await store.dispatch("user/passwordUpdate", payload);
    return response;
  }

  async function passwordRecovery(payload) {
    const response = await store.dispatch("user/passwordRecovery", payload);
    return response;
  }

  async function passwordReset(payload) {
    const response = await store.dispatch("user/passwordReset", payload);
    return response;
  }

  return {
    logged: computed(() => user.value.token !== null),
    user,
    login,
    logout,
    signup,
    update,
    passwordUpdate,
    passwordRecovery,
    passwordReset,
  };
};
