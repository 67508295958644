const redirects = [
  { from: "^/news.php(.*)$", to: "/it-it/news" },
  { from: "^/index.php", to: "/" },
  { from: "^/index.php?lang=IT", to: "/" },
  { from: "^/DE/", to: "/" },
  { from: "^/privaci_policy.php", to: "/it-it/legal-info/privacy-policy" },
  {
    from: "^/download.php",
    to: "/it-it/download-area?tab=catalogs&item=collections",
  },
  { from: "^/ricerca_raggruppamento.php?i", to: "/it-it/find-products" },
  {
    from: "^/ricerca_serie.php?idcon=1$",
    to: "/it-it/discover-collections",
  },
  { from: "^/galleria.php?lang=IT", to: "/" },
  { from: "^/index.php?lang=AL", to: "/" },
  { from: "^/cv.php?lang=IT", to: "/" },
  {
    from: "^/contatti.php?lang=ING",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  {
    from: "^/download.php?lang=CI",
    to: "/it-it/download-area?tab=catalogs&item=collections",
  },
  { from: "^/garanzia.php?lang=IT", to: "/" },
  { from: "^/fantini_newsletter.html", to: "/" },
  {
    from: "^/contatti.php?contact=showroom&idriv(.*)$",
    to: "/it-it/showroom/milan",
  },
  {
    from: "^/ricerca_serie.php?idcon=1&idclaserie=1&tipo",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/galleria.php?idtag=9&nome=Accessori&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from: "^/galleria.php?idtag=5&nome=Aboutwater&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  { from: "^/condizioni-vendita.php?lang=IT", to: "/" },
  {
    from: "^/contatti.php?contact=rivenditori&idrivcla=1&idrivnaz=1",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  {
    from: "^/galleria.php?idtag=6&nome=Doccia&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  { from: "^/credits.php", to: "/" },
  {
    from: "^/condizioni_generali.php",
    to: "/it-it/legal-info/legal-notes",
  },
  {
    from: "^/note_legali.php",
    to: "/it-it/legal-info/legal-notes",
  },
  { from: "^/galleria.php?idtag=8&nome=Ambientazioni&lang=IT", to: "/" },
  { from: "^/galleria.php?idtag=7&nome=Vasca&lang=IT", to: "/" },
  { from: "^/galleria.php?idtag=8&nome=Stellen&lang=TD", to: "/" },
  {
    from: "^/contatti.php?contact=rivenditori&idrivcla=2&idrivnaz=n",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  {
    from: "^/galleria.php?idtag=2&nome=Cucina&lang=IT",
    to: "/it-it/discover-collections",
  },
  { from: "^/login/index.php", to: "/" },
  {
    from: "^/ricerca_serie.php?idcon=10&concept=10&idclaserie=11&tipo",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/contatti.php?contact=rivenditori&flag=si&idrivcla=2&idrivnaz=5",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  {
    from: "^/contatti.php?contact=rivenditori&flag=si&idrivcla=2&idrivnaz=2",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  { from: "^/cura-manutenzione.php?lang=IT", to: "/" },
  { from: "^/concetti.php(.*)$", to: "/it-it/discover-collections" },
  {
    from: "^/serie.php?idcon=1&idclaserie=1&nome=BAGNO&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/serie.php?idcon=1&idclaserie=4&nome=cucina&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/serie.php?idcon=1&idclaserie=3&nome=accessori&lang=it",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=6&nome=bagno&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from: "^/serie.php?idcon=&idclaserie=2&nome=docce&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from: "^/serie.php?idcon=&idclaserie=2&nome=docce&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=10&nome=aboutwater&lang=it",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=10&nome=aboutwater&lang=it",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from: "^/serie.php?idcon=1&idclaserie=9&nome=CUCINA&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=15&nome=sailing&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=14&nome=!!optional&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/serie.php?idcon=2&idclaserie=16&nome=articoli%20vari&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from: "^/serie.php?idcon=10&idclaserie=11&nome=fontane%20bianche&lang=it",
    to: "/it-it/discover-collections?filter=fontane-bianche",
  },
  {
    from: "^/serie.php?idcon=1&idclaserie=2&nome=PROGRAMMA%20DOCCE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/serie.php?idcon=1&idclaserie=5&nome=ricambi%20e%20prolunghe&lang=it",
    to: "/it-it/find-products",
  },
  {
    from:
      "^/serie.php?idcon=2&idclaserie=12&nome=03/2019%20supplement%2009/2018%20pricelist&lang=ing",
    to: "/it-it/discover-collections",
  },
  { from: "^/ricerca.php(.*)$", to: "/it-it/find-products" },
  {
    from:
      "^ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=15&nome=ACQUAPURA",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=17&nome=ACQUATONICA",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=760&nome=ACQUAFIT&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=6&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=12&nome=MARE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=71&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=1&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=6&nome=mint&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=785&nome=SAILING&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=63&nome=LAM%C3%89&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=1&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=3&tipo%5B%5D=69&nome=YOUNG&lang=ING",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=779&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=4&nome=milano&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=6&nome=MINT&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=757&nome=MILANOSLIM&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=10&nome=NOSTROMO&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=72&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=76&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=23&nome=ACQUA%20ZONE",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=785&nome=SAILING&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=10&nome=NOSTROMO&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=4&nome=MILANO&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=754&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=12&nome=mare&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=9&nome=CAF%C3%88&lang=ING",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=12&nome=mare&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=756&nome=nice&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=756&nome=nice&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=782&nome=MYO&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=74&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=782&nome=MYO&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=756&nome=NICE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=6&nome=MINT&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=4&nome=MILANO&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=63&nome=LAM%C3%89&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=40&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=3&tipo%5B%5D=21&nome=LINEA&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=&concept=&idclaserie=0&tipo%5B%5D=17&nome=acquatonica&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=76&nome=acquadolce&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=760&nome=ACQUAFIT&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=13&nome=levante&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=8&nome=AR/38&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=764&nome=NOW&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=17&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=756&nome=NICE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=13&nome=LEVANTE&lang=TD",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=785&nome=SAILING&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=66&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=4&tipo%5B%5D=79&nome=cucina&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=&concept=&idclaserie=0&tipo%5B%5D=12&nome=mare&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=9&nome=caf%C8&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=23&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=14&nome=I%20BALOCCHI",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=13&nome=levante&lang=it",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=76&nome=ACQUADOLCE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=40&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=40&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=9&nome=CAF%C3%88&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=21&nome=LINEA&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=4&nome=milano&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=3&tipo%5B%5D=40&nome=VENEZIA&lang=IT",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=759&nome=ACQUAPURA&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=754&nome=AK/25&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=779&nome=AA/27&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=6&tipo%5B%5D=754&nome=AK/25&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%255B%255D=70&nome=ICONA%2520CLASSIC&lang=ING/",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=73&nome=LEVANTE&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=10&tipo%5B%5D=779&nome=AA/27&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=40&nome=venezia&lang=it",
    to: "/it-it/discover-collections?filter=accessori",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=4&tipo%5B%5D=24&nome=CUCINA&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=8&nome=AR/38&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=5&nome=DOLCE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=6&tipo%5B%5D=25&nome=AF/21&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=6&tipo%5B%5D=25&nome=AF/21&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=2&concept=2&idclaserie=10&tipo%5B%5D=26&nome=AL/23&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=755&nome=I%20BALOCCHI&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=4&tipo%5B%5D=79&nome=KITCHEN&lang=ING",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=71&nome=ICONA%20DECO&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=752&nome=programma%20docce&lang=it",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=755&nome=I%20BALOCCHI&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=781&nome=NOSTROMO%20SMALL&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=70&nome=ICONA%20CLASSIC&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=23&nome=ACQUA%20ZONE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=71&nome=ICONA%20DECO&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=10&concept=10&idclaserie=0&tipo%5B%5D=80&nome=FONTANE%20BIANCHE&lang=IT",
    to: "/it-it/discover-collections?filter=fontane-bianche",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=752&nome=PROGRAMMA%20DOCCE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=23&nome=ACQUA%20ZONE&lang=IT",
    to: "/it-it/discover-collections?filter=programma-docce",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=3&tipo%5B%5D=67&nome=LINEA%20ACCIAIO&lang=IT",
    to: "/it-it/discover-collections",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=758&nome=MILANOSLIM%20OUTDOOR&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=781&nome=NOSTROMO%20SMALL&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=0&tipo%5B%5D=74&nome=nostromo%20acciaio&lang=it",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=73&nome=MINT%20ACERO&lang=SP",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=2&tipo%5B%5D=72&nome=MILANO%20STAINLESS%20STEEL&lang=IT",
    to: "/it-it/discover-collections?filter=fantini",
  },
  {
    from:
      "^/ricerca_serie.php?idcon=1&concept=1&idclaserie=1&tipo%5B%5D=4&nome=MILANO&lang=FR",
    to: "/it-it/contacts/headquarters-and-showrooms",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=43&nome=Sailing",
    to: "/it-it/company/csr/projects/s-a-i-l-i-n-g",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=39&nome=Milano",
    to: "/it-it/showroom/milan",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=37&nome=Designer",
    to: "/it-it/designers",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=38&nome=Milestone",
    to: "/it-it/company/overview",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=52&nome=ALTAGAMMA",
    to: "/it-it/discover-collections",
  },
  { from: "^/pagina_azienda.php?C_MODELLO=41&nome=Zurigo", to: "/" },
  {
    from: "^/pagina_azienda.php?C_MODELLO=42&nome=100%20Fontane",
    to: "/it-it/company/csr",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=40&nome=New%20York",
    to: "/it-it/showroom/new-york",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=50&nome=Fronte%20Lago",
    to: "/it-it/company/waterfront",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=44&nome=Aboutwater&lang=IT",
    to: "/it-it/discover-collections?filter=aboutwater",
  },
  {
    from: "^/pagina_azienda.php?C_MODELLO=51&nome=100%20Fontane%20-%202016",
    to: "/it-it/company/csr",
  },
  { from: "^/concetti.php?lang=ING", to: "/it-it/company/csr" },
  { from: "^/concetti.php?lang=ING", to: "/it-it/company/csr" },
];

export default redirects;
