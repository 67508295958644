import { onMounted, onUnmounted } from "vue";
import { useContext } from "@nuxtjs/composition-api";

export const useRecaptcha3 = () => {
  const { $recaptcha } = useContext();

  onMounted(async () => {
    try {
      await $recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  });

  async function onSubmit() {
    try {
      const recaptchaToken = await $recaptcha.execute();
      if (recaptchaToken && recaptchaToken !== null) {
        return recaptchaToken;
      }
    } catch (error) {
      console.log("Error occurred on recaptcha authentication:", error);
    }
  }

  onUnmounted(() => {
    $recaptcha.destroy();
  });

  return {
    onMounted,
    onSubmit,
  };
};
