export default {
  __name: 'index',
  props: {
  id: {
    type: String,
    required: false,
  },
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["section", "div"].indexOf(value) !== -1;
    },
    default: "section",
  },
  layout: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "normal",
          "header",
          "asideleft",
          "header-asideleft",
          "header-asideleftsmall",
          "split-x",
        ].indexOf(value) !== -1
      );
    },
    default: "normal",
  },
  contentVisibility: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "hidden", "visible"].indexOf(value) !== -1;
    },
    default: "visible",
  },
  mt: {
    type: Boolean,
    required: false,
    default: true,
  },
  container: {
    type: Boolean,
    required: false,
    default: true,
  },
  gapY: {
    type: Boolean,
    required: false,
    default: false,
  },
  dense: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props



return { __sfc: true,props }
}

}