import Vue from "vue";

export default {
  namespaced: true,

  state: function() {
    return {
      privacy: "",
      cookies: "",
      sellCondition: [],
      termsAndConditions: [],
    };
  },

  actions: {},

  mutations: {
    setData(state, data) {
      for (let p in data) {
        Vue.set(state, p, data[p]);
      }
    },
  },
};
