import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { templateRef, onClickOutside } from "@vueuse/core";
import { useScrollDisable } from "/composables/useScrollDisable";

export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  useClose: {
    type: Boolean,
    required: false,
    default: true,
  },
  labelledby: {
    type: String,
    required: true,
  },
  describedby: {
    type: String,
    required: false,
  },
  backdrop: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
  skin: {
    type: String,
    required: false,
    validator: function (value) {
      return ["white", "red", "ice", "gray"].indexOf(value) !== -1;
    },
  },
  transition: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "fade",
          "slide-up",
          "slide-right",
          "slide-left",
          "soft-fade-slide-up",
          "zoom-out",
          "zoom-in",
        ].indexOf(value) !== -1
      );
    },
    default: "fade",
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props




const { disableScroll, enableScroll } = useScrollDisable();
const tpl_modal_wrapper = templateRef("tpl_modal_wrapper");
const tpl_bg = templateRef("tpl_bg");
const tpl_content = templateRef("tpl_content");
const tpl_close = templateRef("tpl_close");
const transitionTL = ref(null);

onClickOutside(tpl_content, (event) => {
  if (props.backdrop === true) emit("hide");
});

function beforeEnter(el) {
  gsap.set(el, { autoAlpha: 0 });
}

function enter(el, done) {
  if (transitionTL.value) {
    transitionTL.value.kill();
    transitionTL.value = null;
  }

  transitionTL.value = new gsap.timeline({
    onStart: () => {
      tpl_modal_wrapper.value.focus();
    },
    onComplete: () => {
      transitionTL.value = null;
      //tpl_modal_wrapper.value.focus();
      done();
    },
  });

  transitionTL.value.set(el, { autoAlpha: 1 });

  if (props.backdrop === true || props.backdrop === "static") {
    transitionTL.value.fromTo(
      tpl_bg.value,
      { autoAlpha: 0 },
      { duration: 1.7, autoAlpha: 0.7, ease: "expo.out" }
    );
  }

  if (props.useClose) {
    transitionTL.value.fromTo(
      tpl_close.value,
      { x: 200 },
      { duration: 0.5, x: 0, ease: "power2.out" },
      0.2
    );
  }

  switch (props.transition) {
    case "zoom-in":
    case "zoom-out":
      transitionTL.value.fromTo(
        tpl_content.value,
        {
          scale: props.transition === "zoom-in" ? 0.9 : 1.1,
          autoAlpha: 0,
        },
        {
          duration: 1,
          scale: 1,
          autoAlpha: 1,
          ease: "expo.inOut",
          force3D: "auto",
        },
        0
      );
      break;

    case "soft-fade-slide-up":
      transitionTL.value.fromTo(
        tpl_content.value,
        { y: "5vh", autoAlpha: 0 },
        {
          duration: 1,
          y: 0,
          autoAlpha: 1,
          ease: "expo.out",
          force3D: "auto",
        },
        0
      );
      break;

    case "slide-up":
      transitionTL.value.fromTo(
        tpl_content.value,
        { y: "110vh" },
        { duration: 1, y: 0, ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "slide-right":
      transitionTL.value.fromTo(
        tpl_content.value,
        { x: "-110vw" },
        { duration: 1, x: 0, ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "slide-left":
      transitionTL.value.fromTo(
        tpl_content.value,
        { x: "110vw" },
        { duration: 1, x: 0, ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "fade":
    default:
      transitionTL.value.fromTo(
        tpl_content.value,
        { autoAlpha: 0 },
        { duration: 0.8, autoAlpha: 1, ease: "sine.out", force3D: "auto" },
        0
      );
      break;
  }
}

function leave(el, done) {
  if (transitionTL.value) {
    transitionTL.value.kill();
    transitionTL.value = null;
  }

  transitionTL.value = new gsap.timeline({
    onComplete: () => {
      transitionTL.value = null;
      done();
    },
  });

  switch (props.transition) {
    case "zoom-in":
    case "zoom-out":
      transitionTL.value.to(
        tpl_content.value,
        {
          duration: 1,
          scale: props.transition === "zoom-in" ? 0.9 : 1.1,
          autoAlpha: 0,
          ease: "expo.inOut",
          force3D: "auto",
        },
        0
      );
      break;

    case "soft-fade-slide-up":
      transitionTL.value.to(
        tpl_content.value,
        {
          duration: 1,
          y: "5vh",
          autoAlpha: 0,
          ease: "expo.inOut",
          force3D: "auto",
        },
        0
      );
      break;

    case "slide-up":
      transitionTL.value.to(
        tpl_content.value,
        { duration: 1, y: "110vh", ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "slide-right":
      transitionTL.value.to(
        tpl_content.value,
        { duration: 1, x: "-110vw", ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "slide-left":
      transitionTL.value.to(
        tpl_content.value,
        { duration: 1, x: "110vw", ease: "expo.inOut", force3D: "auto" },
        0
      );
      break;

    case "fade":
    default:
      transitionTL.value.to(
        tpl_content.value,
        { duration: 0.8, autoAlpha: 0, ease: "sine.out", force3D: "auto" },
        0
      );
      break;
  }

  if (props.useClose) {
    transitionTL.value.to(
      tpl_close.value,
      { duration: 0.5, x: 200, ease: "power2.inOut", force3D: "auto" },
      0
    );
  }

  if (props.backdrop === true || props.backdrop === "static") {
    transitionTL.value.to(
      tpl_bg.value,
      { duration: 1.2, autoAlpha: 0, ease: "expo.inOut", force3D: "auto" },
      props.transition === "fade" ? 0 : 0.2
    );
  }

  transitionTL.value.set(el, { autoAlpha: 0 });
}

onMounted(() => {
  watch(
    () => props.visible,
    (newValue) => {
      newValue ? disableScroll() : enableScroll();
    }
  );
});

onBeforeUnmount(() => {
  enableScroll();
});

return { __sfc: true,props, emit, disableScroll, enableScroll, tpl_modal_wrapper, tpl_bg, tpl_content, tpl_close, transitionTL, beforeEnter, enter, leave }
}

}