const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['router'] = require('../middleware/router.js')
middleware['router'] = middleware['router'].default || middleware['router']

middleware['set-locale'] = require('../middleware/set-locale.js')
middleware['set-locale'] = middleware['set-locale'].default || middleware['set-locale']

export default middleware
