
import { ref, watch } from "vue";
import { templateRef } from "@vueuse/core";
import { useUser } from "/composables/useUser";

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { logged } = useUser();
    const langMenuIsOpen = ref(false);
    const langMenuTL = ref(null);
    const tpl_langMenuBg = templateRef("tpl_langMenuBg");
    const tpl_langMenuContent = templateRef("tpl_langMenuContent");

    function langMenuBeforeEnter(el) {
      if (langMenuTL.value) {
        langMenuTL.value.kill();
        langMenuTL.value = null;
      }

      gsap.set(el, { autoAlpha: 0 });
    }

    function langMenuEnter(el, done) {
      langMenuTL.value = new gsap.timeline({
        onComplete: () => {
          done();
          langMenuTL.value.kill();
          langMenuTL.value = null;
        },
      });

      langMenuTL.value.set(el, { autoAlpha: 1 });

      langMenuTL.value.fromTo(
        tpl_langMenuBg.value,
        { autoAlpha: 0 },
        { duration: 1, autoAlpha: 0.7, ease: "expo.out" }
      );
      langMenuTL.value.fromTo(
        tpl_langMenuContent.value,
        { xPercent: -110 },
        { duration: 0.8, xPercent: 0, ease: "expo.inOut" },
        0
      );
    }

    function langMenuBeforeLeave(el) {
      if (langMenuTL.value) {
        langMenuTL.value.kill();
        langMenuTL.value = null;
      }
    }

    function langMenuLeave(el, done) {
      langMenuTL.value = new gsap.timeline({
        onComplete: () => {
          done();
          langMenuTL.value.kill();
          langMenuTL.value = null;
        },
      });

      langMenuTL.value.to(tpl_langMenuContent.value, {
        duration: 0.8,
        xPercent: -110,
        ease: "expo.inOut",
      });

      langMenuTL.value.to(
        tpl_langMenuBg.value,
        { duration: 1, autoAlpha: 0, ease: "expo.inOut" },
        0
      );
    }

    watch(
      () => props.visible,
      (newVal) => {
        if (newVal === false) langMenuIsOpen.value = false;
      }
    );

    return {
      logged,
      langMenuIsOpen,
      langMenuBeforeEnter,
      langMenuEnter,
      langMenuBeforeLeave,
      langMenuLeave,
    };
  },
};
