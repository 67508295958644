import axios from "axios";
import Vue from "vue";

export const state = () => ({});

export const mutations = {
  setData(state, data) {
    Vue.set(state, "items", data);
  },
};

export const actions = {
  async load({ commit }, params) {
    if (params.payload) {
      commit("setData", params.payload);
      return;
    }

    const { data } = await axios.get(
      `${this.$config.wsURL}/wp-json/ta/v1/get?type=social-networks&lang=it`
    );

    commit("setData", data);
  },
};
