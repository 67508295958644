import { readonly } from "@nuxtjs/composition-api";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "~/tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

// fullConfig.theme.width[4];
// // => '1rem'

// fullConfig.theme.screens.md;
// // => '768px'

// fullConfig.theme.boxShadow["2xl"];
// // => '0 25px 50px -12px rgba(0, 0, 0, 0.25)'

const getters = {};
const actions = {};

export default () => ({
  theme: readonly(fullConfig.theme),
  colors: readonly(fullConfig.theme.colors),
  spacing: readonly(fullConfig.theme.spacing),
  screens: readonly(fullConfig.theme.screens),
  ...getters,
  ...actions,
});
