import { useStore } from "@nuxtjs/composition-api";

export const useDownloadables = () => {
  const store = useStore();

  function getLink(file) {
    if (/:\/\//.test(file)) {
      return file;
    }

    if (/.(jpg|jpeg|png|webp|avif)/i.test(file)) {
      return `${store.state.cloudinaryPath}${file}`;
    } else if (/.pdf/i.test(file)) {
      return `${store.state.cloudinaryPathPdf}${file}`;
    } else {
      return `${store.state.cloudinaryPathZip}${file}`;
    }
  }

  return {
    getLink,
  };
};
