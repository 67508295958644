import { ref, onMounted, nextTick } from "vue";
import { useRoute } from "@nuxtjs/composition-api";
import { useWindowSize, templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  buttons: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props



const route = useRoute();
const { width } = useWindowSize();
const tpl_swiperContainer = templateRef("tpl_swiperContainer");
const swiper = ref(null);

onMounted(() => {
  const $slides = tpl_swiperContainer.value.getElementsByClassName("swiper-slide");
  let initialSlide = 0;

  for(let i = 0, len = $slides.length; i < len; i++) {
    if($slides[i].classList.contains('nuxt-link-active')) {
      initialSlide = i;
      break;
    }
  }

  nextTick(()=> {
    swiper.value = new Swiper(tpl_swiperContainer.value, {
      slidesPerView: 'auto',
      slideToClickedSlide: true,
      setWrapperSize: true,
      threshold: 10,
      spaceBetween: 24,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
      roundLengths: true,
      freeMode: {
        enabled: true,
      },
      centerInsufficientSlides: true,
      resistance : true,
      resistanceRatio : 0,
      sped: 850,
      initialSlide: initialSlide,
      breakpoints: {
        600: {
          slidesOffsetBefore: 32,
          slidesOffsetAfter: 32
        },
        1024: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slideToClickedSlide: false
        }
      }
    });
  });
});

return { __sfc: true,props, route, width, tpl_swiperContainer, swiper }
}

}