module.exports = {
  content: [
    `components/**/*.{vue,js}`,
    `layouts/**/*.vue`,
    `pages/**/*.vue`,
    `plugins/**/*.{js,ts}`,
    `nuxt.config.{js,ts}`,
  ],

  theme: {
    screens: {
      sm: {
        min: "600px",
      },
      md: {
        min: "1024px",
      },
      lg: {
        min: "1280px",
      },
      xl: {
        min: "1536px",
      },
      "2xl": {
        min: "1800px",
      },
      "has-hover": { raw: "(hover: hover)" },
      "no-hover": { raw: "(hover: none)" },
    },
    /* Layout spacing unist: 4 6 8 10 14 18 24 32 48     */
    /* Component spacing units: 1 2 3 4 6 8 10 14 18 24 */
    spacing: {
      0: "0px",
      px: "1px",
      0.5: "2px",
      1: "4px",
      1.5: "6px",
      2: "8px",
      2.5: "10px",
      3: "12px",
      3.5: "14px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "28px",
      8: "32px",
      9: "36px",
      10: "40px",
      11: "44px",
      12: "48px",
      14: "56px",
      15: "60px",
      16: "64px",
      17: "68px",
      18: "72px",
      19: "76px",
      20: "80px",
      22: "88px",
      24: "96px",
      25: "100px",
      28: "112px",
      30: "120px",
      32: "128px",
      34: "136px",
      36: "144px",
      38: "152px",
      40: "160px",
      44: "176px",
      45: "180px",
      46: "184px",
      48: "192px",
      50: "200px",
      56: "224px",
      58: "232px",
      60: "240px",
      64: "256px",
      78: "312px",
    },

    fontFamily: {
      book: ["var(--font-family-book)", "sans-serif"],
      regular: ["var(--font-family-regular)", "sans-serif"],
      demi: ["var(--font-family-semibold)", "sans-serif"],
    },

    lineHeight: {
      none: 1,
      tight: "1.1",
      snug: "1.2",
      normal: "1.3",
      relaxed: "1.4",
      loose: "1.8",
    },

    fontSize: {
      10: ["10px", "14px"],
      12: ["12px", "16px"],
      14: ["14px", "20px"],
      "heading-1": ["clamp(40px, 8vw, 160px)", "1.1"],
      "heading-2": ["clamp(36px, 5vw, 96px)", "1.2"],
      "heading-3": ["clamp(32px, 3vw, 64px)", "1.2"],
      "heading-4": ["clamp(28px, 2vw, 48px)", "1.4"],
      "heading-5": ["clamp(24px, 1.5vw, 32px)", "1.2"],
      "heading-6": ["clamp(20px, 1.5vw, 32px)", "1.4"],
      subheading: ["clamp(16px, 1.2vw, 24px)", "1.8"],
      topheading: ["clamp(12px, 0.83vw, 16px)", "1.1"],
      "topheading-small": ["clamp(12px, 0.68vw, 13px)", "1.1"],
      "paragraph-big": ["clamp(16px, 0.9vw, 18px)", "1.8"],
      nav: ["clamp(12px, 1.37vw, 14px)", "1"],
    },

    letterSpacing: {
      tightest: "-.01em",
      tighter: "-.005em",
      tight: "-.0025em",
      normal: "0",
      wide: ".025em",
      wider: ".05em",
      widest: ".1em",
      broad: ".15em",
      broader: ".2em",
      broadest: ".4em",
    },

    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      gray: {
        100: "#F3F5F7",
        200: "#dbdddf",
        300: "#c0c3c4",
        400: "#a5a8aa",
        500: "#8a8d8f",
        600: "#727679",
        700: "#606467",
        800: "#4c5053",
        900: "#383b3d",
      },
      red: {
        100: "#fdecec",
        200: "#fac2c2",
        300: "#f69898",
        400: "#f26d6d",
        500: "#ef4444",
        600: "#e61414",
        700: "#b20f0f",
        800: "#7f0b0b",
        900: "#4b0606",
      },
      yellow: {
        100: "#fef9ea",
        200: "#fce9b6",
        300: "#fad87b",
        400: "#f7c845",
        500: "#f5b70b",
        600: "#ce9907",
        700: "#a27808",
        800: "#7a5b05",
        900: "#4e3a03",
      },
      blue: {
        100: "#eaf3fe",
        200: "#bfd7fc",
        300: "#94bbfa",
        400: "#679ff8",
        500: "#3b82f6",
        600: "#0b61ee",
        700: "#094cb9",
        800: "#063684",
        900: "#05204e",
      },
      green: {
        100: "#ecfdf6",
        200: "#acf7d9",
        300: "#6af1bb",
        400: "#29ea9d",
        500: "#12ba77",
        600: "#0e9e65",
        700: "#0b8253",
        800: "#0b6641",
        900: "#064a30",
      },
      "brand-primary": {
        100: "#feecef",
        200: "#f9aeba",
        300: "#f57086",
        400: "#f12d4d",
        500: "#ce0e2d",
        600: "#ac0c26",
        700: "#8f0a20",
        800: "#6e0719",
        900: "#4c0511",
      },
      "brand-secondary": {
        100: "#edf3f7",
        200: "#cadae7",
        300: "#a6c0d6",
        400: "#83a7c6",
        500: "#5f8db5",
        600: "#507499",
        700: "#415b7d",
        800: "#314260",
        900: "#222944",
      },
      facebook: "#1877f2",
      google: "#dd4b39",
      linkedin: "#2867b2",
      pinterest: "#dd001b",
      twitter: "#1da1f2",
      youtube: "#ff0000",
      vimeo: "#1ab7ea",
    },

    extend: {
      aspectRatio: {
        "4/5": "4 / 5",
        "3/2": "3 / 2",
        "2/1": "2 / 1",
        "1/1": "1 / 1",
        "16/9": "16 / 9",
        "16/7": "16 / 7",
      },
      textColor: {
        skin: {
          base: "var(--color-text-base)",
          muted: "var(--color-text-muted)",
          inverted: "var(--color-text-inverted)",
          disabled: "var(--color-text-disabled)",
          warning: "var(--color-text-warning)",
          danger: "var(--color-text-danger)",
          success: "var(--color-text-success)",
        },
      },
      borderColor: {
        skin: {
          base: "var(--color-text-base)",
          muted: "var(--color-text-muted)",
          inverted: "var(--color-text-inverted)",
          disabled: "var(--color-text-disabled)",
          warning: "var(--color-text-warning)",
          danger: "var(--color-text-danger)",
          success: "var(--color-text-success)",
        },
      },
      backgroundColor: {
        skin: {
          fill: "var(--color-fill)",
          "fill-inverted": "var(--color-fill-inverted)",
          "fill-warning": "var(--color-fill-warning)",
          "fill-danger": "var(--color-fill-danger)",
          "fill-success": "var(--color-fill-success)",
          surface: "var(--color-surface)",
        },
      },
      zIndex: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
        995: 995,
        996: 996,
        997: 997,
        998: 998,
        999: 999,
        1000: 1000,
      },
      transitionDuration: {
        400: "400ms",
      },
    },
  },

  plugins: [
    require("@tailwindcss/forms")
  ],
};
