// export default function(to, from, savedPosition) {
//   if (savedPosition) {
//     return savedPosition;
//   } else {
//     return { x: 0, y: 0 };
//   }
// }

export default function (to, from, savedPosition) {
  window.dispatchEvent(
    new CustomEvent("scrollBehavior", {
      bubbles: false,
      detail: { position: savedPosition },
    })
  );
  return new Promise((resolve, reject) => {
    if (savedPosition) {
      resolve(savedPosition);
    } else {
      window.addEventListener("curtainFullVisible", resolvePromise);
      function resolvePromise(event) {
        resolve({ x: 0, y: 0 });
        window.removeEventListener("curtainFullVisible", resolvePromise);
      }
    }
  });
}
