import { useStore } from "@nuxtjs/composition-api";
import min from "lodash/min";
import values from "lodash/values";

export const useImageSources = () => {
  const store = useStore();

  const fparam = store.state.iOs
    ? "f_jpg"
    : store.state.supportsAvif
    ? "f_avif"
    : "f_auto";

  function getSources(url, queries) {
    let obj = {
      url: `https://res.cloudinary.com/thinking/image/upload/w_${
        queries.base ? queries.base : min(values(queries))
      },c_limit/${fparam}/q_auto:eco/${store.state.cloudinaryFolder}/${url}`,
      sources: {},
    };
    for (let mq in queries) {
      let f = store.state.iOs
        ? "f_jpg"
        : queries[mq] > 3500
        ? "f_auto"
        : fparam;
      obj.sources[
        mq
      ] = `https://res.cloudinary.com/thinking/image/upload/w_${queries[mq]},c_limit/${fparam}/q_auto:eco/${store.state.cloudinaryFolder}/${url}`;
    }

    return obj;
  }

  function getMedia(media, queries) {
    const obj = JSON.parse(JSON.stringify(media));
    if (obj.type == "image") {
      obj.src = getSources(obj.src, queries);
    } else {
      obj.poster = `${store.state.cloudinaryPath}${obj.poster}`;
    }

    return obj;
  }

  function getSrcset(url, queries) {
    /* 
    
    srcset="elva-fairy-480w.jpg 480w,
            elva-fairy-800w.jpg 800w"

    sizes="(max-width: 600px) 480px,
           800px"
    
    */

    const screens = [
      { prop: "sm", value: "600" },
      { prop: "md", value: "1024" },
      { prop: "lg", value: "1280" },
      { prop: "xl", value: "1536" },
      { prop: "2xl", value: "1800" },
    ];

    const srcset = [];
    const sizes = [];

    for (let i = 0; i < screens.length; i++) {
      if (queries[screens[i].prop]) {
        let f = queries[screens[i].prop] > 3500 ? "f_auto" : fparam;
        srcset.push(
          `https://res.cloudinary.com/thinking/image/upload/w_${
            queries[screens[i].prop]
          },c_limit/${store.state.iOs ? "f_jpg" : f}/q_auto:eco/${
            store.state.cloudinaryFolder
          }/${url} ${screens[i].value}w`
        );

        sizes.push(
          `(min-width: ${screens[i].value}px) ${queries[screens[i].prop]}w`
        );
      }
    }

    return {
      srcset: srcset.join(", "),
      sizes: sizes.join(", "),
    };
  }

  function getSrc(url, width) {
    let f = store.state.iOs ? "f_jpg" : width > 3500 ? "f_auto" : fparam;

    return `https://res.cloudinary.com/thinking/image/upload/${
      width ? `w_${width},c_limit/` : ""
    }${fparam}/q_auto:eco/${store.state.cloudinaryFolder}/${url}`;
  }

  return {
    getSrc,
    getSources,
    getMedia,
    getSrcset,
  };
};
