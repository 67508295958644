
export default {
  head() {
    const title = {
      it: "Fantini Rubinetti",
      en: "Fantini Rubinetti",
      fr: "Fantini Rubinetti",
      de: "Fantini Rubinetti",
      es: "Fantini Rubinetti",
      zh: "Fantini Rubinetti",
      ru: "Fantini Rubinetti",
    };

    const description = {
      it: "Fantini rubinetti per il bagno, vasche da bagno, docce, miscelatori e rubinetti per la cucina. Rubinetteria Fantini design e stile Made in Italy.",
      en: "Fantini Rubinetti for the bathroom, bathtubs for the bathroom, showers, washbasin mixers and kitchen taps. Fantini Rubinetti design and style Made in Italy. ",
      fr: "Robinets Fantini pour la salle de bains, baignoires, douches, m\u00e9langeurs et robinets pour la cuisine. Robinets Fantini design et style Fabriqu\u00e9 en Italie.",
      de: "Fantini Armaturen f\u00fcr das Badezimmer. Badewannen, Duschen, Waschtischarmaturen und K\u00fcchenarmaturen. Fantini Armaturen - Design und Stil Made in Italy.",
      es: "Fantini grifer\u00eda para el ba\u00f1o, ba\u00f1eras, duchas, mezcladores y grifos para cocina. Rubinetteria Fantini dise\u00f1o y estilo Made in Italy. ",
      zh: "\u51e1\u8482\u5c3c\u516c\u53f8\u4e3a\u6d74\u5ba4\u63d0\u4f9b\u4e94\u91d1\u4ea7\u54c1\uff0c\u6d74\u5ba4\u7528\u7684\u6d74\u7f38\u9f99\u5934\u3001\u6dcb\u6d74\u3001\u9762\u76c6\u9f99\u5934\u548c\u53a8\u623f\u9f99\u5934\u3002\u51e1\u8482\u5c3c\u516c\u53f8\u610f\u5927\u5229\u5236\u9020\u7684\u8bbe\u8ba1\u548c\u98ce\u683c\u3002",
      ru: "Fantini Rubinetti \u0434\u043b\u044f \u0432\u0430\u043d\u043d\u043e\u0439 \u043a\u043e\u043c\u043d\u0430\u0442\u044b, \u0432\u0430\u043d\u043d\u044b, \u0434\u0443\u0448\u0435\u0432\u044b\u0435 \u043a\u0430\u0431\u0438\u043d\u044b, \u0441\u043c\u0435\u0441\u0438\u0442\u0435\u043b\u0438 \u0434\u043b\u044f \u0440\u0430\u043a\u043e\u0432\u0438\u043d\u044b \u0438 \u043a\u0443\u0445\u043e\u043d\u043d\u044b\u0435 \u0441\u043c\u0435\u0441\u0438\u0442\u0435\u043b\u0438. \u0414\u0438\u0437\u0430\u0439\u043d \u0438 \u0441\u0442\u0438\u043b\u044c Fantini Rubinetti - \u0421\u0434\u0435\u043b\u0430\u043d\u043e \u0432 \u0418\u0442\u0430\u043b\u0438\u0438.",
    };

    const alt = {
      it: "Fantini Rubinetti - Design e stile Made in Italy.",
      en: "Fantini Rubinetti - Design and Style Made in Italy.",
      fr: "Fantini Rubinetti - Design et Style Made in Italy.",
      de: "Fantini Rubinetti - Design und Stil Made in Italy",
      es: "Fantini Rubinetti - Dise\u00f1o y estilo Made in Italy.",
      zh: "Fantini Rubinetti - \u610f\u5927\u5229\u5236\u9020\u7684\u8bbe\u8ba1\u548c\u98ce\u683c\u3002",
      ru: "Fantini Rubinetti - \u0434\u0438\u0437\u0430\u0439\u043d \u0438 \u0441\u0442\u0438\u043b\u044c \u0421\u0434\u0435\u043b\u0430\u043d\u043e \u0432 \u0418\u0442\u0430\u043b\u0438\u0438.",
    };

    const canonical = {
      it: "/it-it",
      en: "/en-ww",
      fr: "/fr-ww",
      de: "/de-ww",
      es: "/es-ww",
      zh: "/zh-ww",
      ru: "/ru-ww",
    };

    const meta = [
      {
        name: "description",
        hid: "description",
        content: description[this.$store.state.reqLang],
      },
      {
        hid: "og:title",
        content: title[this.$store.state.reqLang],
        property: "og:title",
      },
      {
        hid: "og:description",
        content: description[this.$store.state.reqLang],
        property: "og:description",
      },
      {
        hid: "og:image",
        content: "fantini-ph.jpg",
        property: "og:image",
      },
      {
        hid: "og:image:width",
        content: 1500,
        property: "og:image:width",
      },
      {
        hid: "og:image:height",
        content: 500,
        property: "og:image:height",
      },
      {
        hid: "og:url",
        content: `https://fantini.it/xx-${this.$store.state.reqLang}/`,
        property: "og:url",
      },
      {
        hid: "twitter:card",
        content: "fantini-ph.jpg",
        name: "twitter:card",
      },
      {
        hid: "og:site_name",
        content: "Fantini Rubinetti",
        property: "og:site_name",
      },
      {
        hid: "twitter:image:alt",
        content: alt[this.$store.state.reqLang],
        name: "twitter:image:alt",
      },
    ];

    if (this.$store.state.staging) {
      meta.push({ hid: "robots", name: "noindex", content: "" });
    }

    console.log({
      title: title[this.$store.state.reqLang],
      meta: meta,
    });

    return {
      title: title[this.$store.state.reqLang],
      meta: meta,
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: `https://www.fantini.it${canonical[this.$store.state.reqLang]}`,
        },
      ],
    };
  },
  setup(props) {
    /* PAGE LEAVE TRANSITIONS */
    function pageBeforeLeave(event) {
      console.log("%c***** page before leave", "color: magenta;");
    }

    function pageLeave(event) {
      console.log("%c***** page leave", "color: magenta;");
      event.done();
    }

    function pageAfterLeave(event) {
      console.log("%c***** page after leave", "color: magenta;");
    }

    function pageLeaveCancelled(event) {
      console.log("%c***** page leave cancelled", "color: magenta;");
    }
    /* end PAGE LEAVE TRANSITIONS *

    /* PAGE ENTER TRANSITIONS */
    function pageBeforeEnter(event) {
      console.log("%c***** page before enter", "color: lime;");
    }

    function pageEnter(event) {
      console.log("%c***** page enter", "color: lime;");
      event.done();
    }

    function pageAfterEnter(event) {
      console.log("%c***** page after enter", "color: lime;");
    }

    function pageEnterCancelled(event) {
      console.log("%c***** page enter cancelled", "color: lime;");
    }
    /* end PAGE ENTER TRANSITIONS */

    return {
      pageBeforeEnter,
      pageEnter,
      pageAfterEnter,
      pageEnterCancelled,
      pageBeforeLeave,
      pageLeave,
      pageAfterLeave,
      pageLeaveCancelled,
    };
  },
};
