import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _17e20cb1 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _5fcaa019 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _758e0e03 = () => interopDefault(import('../pages/_locale/index.vue' /* webpackChunkName: "pages/_locale/index" */))
const _4cfb8418 = () => interopDefault(import('../pages/_locale/account-activation/index.vue' /* webpackChunkName: "pages/_locale/account-activation/index" */))
const _318a7429 = () => interopDefault(import('../pages/_locale/careers/index.vue' /* webpackChunkName: "pages/_locale/careers/index" */))
const _f8252658 = () => interopDefault(import('../pages/_locale/confirm-account/index.vue' /* webpackChunkName: "pages/_locale/confirm-account/index" */))
const _4f2bec5c = () => interopDefault(import('../pages/_locale/designers/index.vue' /* webpackChunkName: "pages/_locale/designers/index" */))
const _a33a54da = () => interopDefault(import('../pages/_locale/discover-collections/index.vue' /* webpackChunkName: "pages/_locale/discover-collections/index" */))
const _723dc9b4 = () => interopDefault(import('../pages/_locale/download-area/index.vue' /* webpackChunkName: "pages/_locale/download-area/index" */))
const _60330828 = () => interopDefault(import('../pages/_locale/find-products/index.vue' /* webpackChunkName: "pages/_locale/find-products/index" */))
const _40c6b235 = () => interopDefault(import('../pages/_locale/news/index.vue' /* webpackChunkName: "pages/_locale/news/index" */))
const _2c4744d6 = () => interopDefault(import('../pages/_locale/preview-test.vue' /* webpackChunkName: "pages/_locale/preview-test" */))
const _336a3320 = () => interopDefault(import('../pages/_locale/professionals/index.vue' /* webpackChunkName: "pages/_locale/professionals/index" */))
const _e58580c8 = () => interopDefault(import('../pages/_locale/projects/index.vue' /* webpackChunkName: "pages/_locale/projects/index" */))
const _69d692a2 = () => interopDefault(import('../pages/_locale/reset-password-link/index.vue' /* webpackChunkName: "pages/_locale/reset-password-link/index" */))
const _413a3dec = () => interopDefault(import('../pages/_locale/search/index.vue' /* webpackChunkName: "pages/_locale/search/index" */))
const _099a607a = () => interopDefault(import('../pages/_locale/signup/index.vue' /* webpackChunkName: "pages/_locale/signup/index" */))
const _4b780c67 = () => interopDefault(import('../pages/_locale/account/lists/index.vue' /* webpackChunkName: "pages/_locale/account/lists/index" */))
const _d0e8f08a = () => interopDefault(import('../pages/_locale/account/profile/index.vue' /* webpackChunkName: "pages/_locale/account/profile/index" */))
const _626c82b8 = () => interopDefault(import('../pages/_locale/company/csr/index.vue' /* webpackChunkName: "pages/_locale/company/csr/index" */))
const _0dc76130 = () => interopDefault(import('../pages/_locale/company/dna-design/index.vue' /* webpackChunkName: "pages/_locale/company/dna-design/index" */))
const _b204e4a6 = () => interopDefault(import('../pages/_locale/company/overview/index.vue' /* webpackChunkName: "pages/_locale/company/overview/index" */))
const _1ad2c9e1 = () => interopDefault(import('../pages/_locale/company/quality/index.vue' /* webpackChunkName: "pages/_locale/company/quality/index" */))
const _0cb63a26 = () => interopDefault(import('../pages/_locale/company/waterfront/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/index" */))
const _7bef6c2d = () => interopDefault(import('../pages/_locale/contacts/customer-care-and-spare-parts/index.vue' /* webpackChunkName: "pages/_locale/contacts/customer-care-and-spare-parts/index" */))
const _073718f6 = () => interopDefault(import('../pages/_locale/contacts/customer-service/index.vue' /* webpackChunkName: "pages/_locale/contacts/customer-service/index" */))
const _bda091a2 = () => interopDefault(import('../pages/_locale/contacts/headquarters-and-showrooms/index.vue' /* webpackChunkName: "pages/_locale/contacts/headquarters-and-showrooms/index" */))
const _3be77e7c = () => interopDefault(import('../pages/_locale/contacts/professional-consulting/index.vue' /* webpackChunkName: "pages/_locale/contacts/professional-consulting/index" */))
const _781e92f4 = () => interopDefault(import('../pages/_locale/contacts/sales-points/index.vue' /* webpackChunkName: "pages/_locale/contacts/sales-points/index" */))
const _fec62660 = () => interopDefault(import('../pages/_locale/legal-info/cookie-policy/index.vue' /* webpackChunkName: "pages/_locale/legal-info/cookie-policy/index" */))
const _4094527c = () => interopDefault(import('../pages/_locale/legal-info/legal-notes/index.vue' /* webpackChunkName: "pages/_locale/legal-info/legal-notes/index" */))
const _13a949f8 = () => interopDefault(import('../pages/_locale/legal-info/privacy-policy/index.vue' /* webpackChunkName: "pages/_locale/legal-info/privacy-policy/index" */))
const _47342c04 = () => interopDefault(import('../pages/_locale/press/kit/index.vue' /* webpackChunkName: "pages/_locale/press/kit/index" */))
const _0fdc1bf7 = () => interopDefault(import('../pages/_locale/press/request/index.vue' /* webpackChunkName: "pages/_locale/press/request/index" */))
const _60c9d1c6 = () => interopDefault(import('../pages/_locale/press/review/index.vue' /* webpackChunkName: "pages/_locale/press/review/index" */))
const _5e974107 = () => interopDefault(import('../pages/_locale/account/profile/password-reset/index.vue' /* webpackChunkName: "pages/_locale/account/profile/password-reset/index" */))
const _31cede32 = () => interopDefault(import('../pages/_locale/account/profile/password-update/index.vue' /* webpackChunkName: "pages/_locale/account/profile/password-update/index" */))
const _2be63ab8 = () => interopDefault(import('../pages/_locale/company/waterfront/la-fabbrica-sul-lago/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/la-fabbrica-sul-lago/index" */))
const _6c556662 = () => interopDefault(import('../pages/_locale/company/waterfront/lake-time/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/lake-time/index" */))
const _2e2bfd7a = () => interopDefault(import('../pages/_locale/company/csr/projects/_id.vue' /* webpackChunkName: "pages/_locale/company/csr/projects/_id" */))
const _0afeb6c4 = () => interopDefault(import('../pages/_locale/news/article/_id.vue' /* webpackChunkName: "pages/_locale/news/article/_id" */))
const _426c3568 = () => interopDefault(import('../pages/_locale/collection/_id.vue' /* webpackChunkName: "pages/_locale/collection/_id" */))
const _ab7d5478 = () => interopDefault(import('../pages/_locale/designers/_id.vue' /* webpackChunkName: "pages/_locale/designers/_id" */))
const _203069f1 = () => interopDefault(import('../pages/_locale/lists/_id.vue' /* webpackChunkName: "pages/_locale/lists/_id" */))
const _85a111ea = () => interopDefault(import('../pages/_locale/product/_id.vue' /* webpackChunkName: "pages/_locale/product/_id" */))
const _31e30df8 = () => interopDefault(import('../pages/_locale/projects/_id.vue' /* webpackChunkName: "pages/_locale/projects/_id" */))
const _458d37e2 = () => interopDefault(import('../pages/_locale/showroom/_id.vue' /* webpackChunkName: "pages/_locale/showroom/_id" */))
const _5d88c174 = () => interopDefault(import('../pages/_locale/serie/_id/concept/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/concept/index" */))
const _656ccff7 = () => interopDefault(import('../pages/_locale/serie/_id/downloads/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/downloads/index" */))
const _7f2621de = () => interopDefault(import('../pages/_locale/serie/_id/gallery/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/gallery/index" */))
const _ec6d70a4 = () => interopDefault(import('../pages/_locale/serie/_id/products/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/products/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _17e20cb1,
    name: "auth"
  }, {
    path: "/",
    component: _5fcaa019,
    name: "index"
  }, {
    path: "/:locale",
    component: _758e0e03,
    name: "locale"
  }, {
    path: "/:locale/account-activation",
    component: _4cfb8418,
    name: "locale-account-activation"
  }, {
    path: "/:locale/careers",
    component: _318a7429,
    name: "locale-careers"
  }, {
    path: "/:locale/confirm-account",
    component: _f8252658,
    name: "locale-confirm-account"
  }, {
    path: "/:locale/designers",
    component: _4f2bec5c,
    name: "locale-designers"
  }, {
    path: "/:locale/discover-collections",
    component: _a33a54da,
    name: "locale-discover-collections"
  }, {
    path: "/:locale/download-area",
    component: _723dc9b4,
    name: "locale-download-area"
  }, {
    path: "/:locale/find-products",
    component: _60330828,
    name: "locale-find-products"
  }, {
    path: "/:locale/news",
    component: _40c6b235,
    name: "locale-news"
  }, {
    path: "/:locale/preview-test",
    component: _2c4744d6,
    name: "locale-preview-test"
  }, {
    path: "/:locale/professionals",
    component: _336a3320,
    name: "locale-professionals"
  }, {
    path: "/:locale/projects",
    component: _e58580c8,
    name: "locale-projects"
  }, {
    path: "/:locale/reset-password-link",
    component: _69d692a2,
    name: "locale-reset-password-link"
  }, {
    path: "/:locale/search",
    component: _413a3dec,
    name: "locale-search"
  }, {
    path: "/:locale/signup",
    component: _099a607a,
    name: "locale-signup"
  }, {
    path: "/:locale/account/lists",
    component: _4b780c67,
    name: "locale-account-lists"
  }, {
    path: "/:locale/account/profile",
    component: _d0e8f08a,
    name: "locale-account-profile"
  }, {
    path: "/:locale/company/csr",
    component: _626c82b8,
    name: "locale-company-csr"
  }, {
    path: "/:locale/company/dna-design",
    component: _0dc76130,
    name: "locale-company-dna-design"
  }, {
    path: "/:locale/company/overview",
    component: _b204e4a6,
    name: "locale-company-overview"
  }, {
    path: "/:locale/company/quality",
    component: _1ad2c9e1,
    name: "locale-company-quality"
  }, {
    path: "/:locale/company/waterfront",
    component: _0cb63a26,
    name: "locale-company-waterfront"
  }, {
    path: "/:locale/contacts/customer-care-and-spare-parts",
    component: _7bef6c2d,
    name: "locale-contacts-customer-care-and-spare-parts"
  }, {
    path: "/:locale/contacts/customer-service",
    component: _073718f6,
    name: "locale-contacts-customer-service"
  }, {
    path: "/:locale/contacts/headquarters-and-showrooms",
    component: _bda091a2,
    name: "locale-contacts-headquarters-and-showrooms"
  }, {
    path: "/:locale/contacts/professional-consulting",
    component: _3be77e7c,
    name: "locale-contacts-professional-consulting"
  }, {
    path: "/:locale/contacts/sales-points",
    component: _781e92f4,
    name: "locale-contacts-sales-points"
  }, {
    path: "/:locale/legal-info/cookie-policy",
    component: _fec62660,
    name: "locale-legal-info-cookie-policy"
  }, {
    path: "/:locale/legal-info/legal-notes",
    component: _4094527c,
    name: "locale-legal-info-legal-notes"
  }, {
    path: "/:locale/legal-info/privacy-policy",
    component: _13a949f8,
    name: "locale-legal-info-privacy-policy"
  }, {
    path: "/:locale/press/kit",
    component: _47342c04,
    name: "locale-press-kit"
  }, {
    path: "/:locale/press/request",
    component: _0fdc1bf7,
    name: "locale-press-request"
  }, {
    path: "/:locale/press/review",
    component: _60c9d1c6,
    name: "locale-press-review"
  }, {
    path: "/:locale/account/profile/password-reset",
    component: _5e974107,
    name: "locale-account-profile-password-reset"
  }, {
    path: "/:locale/account/profile/password-update",
    component: _31cede32,
    name: "locale-account-profile-password-update"
  }, {
    path: "/:locale/company/waterfront/la-fabbrica-sul-lago",
    component: _2be63ab8,
    name: "locale-company-waterfront-la-fabbrica-sul-lago"
  }, {
    path: "/:locale/company/waterfront/lake-time",
    component: _6c556662,
    name: "locale-company-waterfront-lake-time"
  }, {
    path: "/:locale/company/csr/projects/:id?",
    component: _2e2bfd7a,
    name: "locale-company-csr-projects-id"
  }, {
    path: "/:locale/news/article/:id?",
    component: _0afeb6c4,
    name: "locale-news-article-id"
  }, {
    path: "/:locale/collection/:id?",
    component: _426c3568,
    name: "locale-collection-id"
  }, {
    path: "/:locale/designers/:id",
    component: _ab7d5478,
    name: "locale-designers-id"
  }, {
    path: "/:locale/lists/:id?",
    component: _203069f1,
    name: "locale-lists-id"
  }, {
    path: "/:locale/product/:id?",
    component: _85a111ea,
    name: "locale-product-id"
  }, {
    path: "/:locale/projects/:id",
    component: _31e30df8,
    name: "locale-projects-id"
  }, {
    path: "/:locale/showroom/:id?",
    component: _458d37e2,
    name: "locale-showroom-id"
  }, {
    path: "/:locale/serie/:id?/concept",
    component: _5d88c174,
    name: "locale-serie-id-concept"
  }, {
    path: "/:locale/serie/:id?/downloads",
    component: _656ccff7,
    name: "locale-serie-id-downloads"
  }, {
    path: "/:locale/serie/:id?/gallery",
    component: _7f2621de,
    name: "locale-serie-id-gallery"
  }, {
    path: "/:locale/serie/:id?/products",
    component: _ec6d70a4,
    name: "locale-serie-id-products"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
