import { ref } from "vue";
import { useStore } from "@nuxtjs/composition-api";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";
import { useUser } from "/composables/useUser";


export default {
  __name: 'index',
  setup(__props) {

const { logout } = useUser();
const store = useStore();
const logOutFormErrors = ref([]);
const { onSubmit } = useRecaptcha3();

async function handleLogout() {
  try {
    const recaptchaToken = await onSubmit();
    if (recaptchaToken) {
      const response = await logout();
      if (!response.success) {
        logOutFormErrors.value = [store.state.labels.error_occurred];
      }
    }
  } catch (e) {
    logOutFormErrors.value = [store.state.labels.error_occurred];
  }
}

return { __sfc: true,logout, store, logOutFormErrors, onSubmit, handleLogout }
}

}