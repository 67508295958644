import { ref, onMounted } from "vue";
import { useUser } from "/composables/useUser";


export default {
  __name: 'index',
  setup(__props) {

const { logged } = useUser();
const showModal = ref(false);
const year = ref();

function handleClick(event) {
  if (!logged.value) {
    event.preventDefault();
    event.stopPropagation();

    alert("Not logged");
  }
}

onMounted(() => {
  year.value = new Date().getFullYear();
});

return { __sfc: true,logged, showModal, year, handleClick }
}

}