
import { computed } from "vue";
import { useStore } from "@nuxtjs/composition-api";
export default {
  head() {
    const meta = [];
    if (this.$store.state.staging) {
      meta.push({ hid: "robots", name: "noindex", content: "" });
    }
    return {
      meta: meta,
    };
  },
  props: ["error"],
  setup(props) {
    const store = useStore();

    const errorType = computed(() => {
      let obj;
      switch (props.error.statusCode) {
        case 404:
          obj = {
            title: store.state.labels.page_not_found,
            text: store.state.labels.page_not_found_text,
          };
          return obj;
          break;
        case 500:
          obj = {
            title: store.state.labels.internal_server_error,
            text: store.state.labels.error_occurred,
          };
          return obj;
          break;
      }
    });
    return {
      errorType,
    };
  },
};
