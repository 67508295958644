import { ref, computed, watch } from "vue";
import { useRoute } from "@nuxtjs/composition-api";
import { parsePhoneNumberFromString } from "libphonenumber-js";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  stretched: {
    type: Boolean,
    required: false,
    default: false,
  },
  pressed: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    type: [String, Object],
    required: false,
  },
  download: {
    type: String,
    required: false,
  },
  externalLink: {
    type: String,
    required: false,
  },
  phone: {
    type: String,
    required: false,
    validator: function (value) {
      return parsePhoneNumberFromString(value) ? true : false;
    },
  },
  email: {
    type: String,
    required: false,
    validator: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      );
    },
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "normal", "big"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  disableTextSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  downloadPdfAsNewTab: {
    type: Boolean,
    required: false,
    default: true,
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
  buttonTag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["summary", "button"].indexOf(value) !== -1;
    },
    default: "button",
  },
},
  setup(__props) {

const props = __props



const route = useRoute();
const showTitle = ref(true);
let downloadAttr;

const attrs = computed(() => {
  let value = {
    is: null,
    type: null,
    to: null,
    href: null,
    target: null,
    tabindex: null,
    role: null,
  };

  if (props.to) {
    value.is = "nuxt-link";

    if (typeof props.to === "string") {
      value.to = `/${route.value.params.locale}${props.to}`;
      value.dataTo = value.to;
    } else {
      value.to = {
        path: `/${route.value.params.locale}${props.to.path}`,
        query: props.to.query,
      };

      value.dataTo = value.to.path;
    }
  } else if (
    props.externalLink ||
    props.phone ||
    props.email ||
    props.download
  ) {
    value.is = "a";
    if (props.externalLink) {
      value.href = props.externalLink;
      value.target = "_blank";
    } else if (props.phone) value.href = "tel:" + props.phone;
    else if (props.email) value.href = encodeURI(`mailto:${props.email}`);
    else if (props.download) {
      // if PDF open in newTab instead of download
      if (props.downloadPdfAsNewTab && /.pdf/i.test(props.download)) {
        value.target = "_blank";
      } else {
        downloadAttr = "download";
        setDownloadAttr();
      }

      value.href = encodeURI(`${props.download}`);
    }
  } else {
    value.is = props.buttonTag;
  }

  if (props.disabled && (attrs.is === "nuxt-link" || attrs.is === "a")) {
    value.tabindex = -1;
  }

  if (attrs.is === "nuxt-link" || attrs.is === "a" || value.is === "summary") {
    value.role = "link";
  } else if (value.is === "button") {
    value.type = props.type;
  }

  return value;
});

function setDownloadAttr() {
  downloadAttr = props.download ? { [`download`]: `` } : null;
}

watch(() => props.download, setDownloadAttr);

setDownloadAttr();

return { __sfc: true,props, route, showTitle, downloadAttr, attrs, setDownloadAttr }
}

}