export const state = () => ({
  isLoading: false,
  message: "",
});

export const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },
};

export const getters = {
  loadingStatus(state) {
    return state.loading;
  },
  loadingMessage(state) {
    return state.message;
  },
};
