import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  __name: 'index',
  props: {
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["filled", "normal", "empty"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  icon: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  stretched: {
    type: Boolean,
    required: false,
    default: false,
  },
  pressed: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    type: [String, Object],
    required: false,
  },
  download: {
    type: String,
    required: false,
  },
  externalLink: {
    type: String,
    required: false,
  },
  phone: {
    type: String,
    required: false,
    validator: function (value) {
      return parsePhoneNumberFromString(value) ? true : false;
    },
  },
  email: {
    type: String,
    required: false,
    validator: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      );
    },
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "normal", "big"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
},
  setup(__props) {

const props = __props



return { __sfc: true,props }
}

}